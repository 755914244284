import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs';
import erpAxios from '../../api/ErpAxios';
import Loader from '../Loader';
import ConfirmPayment from './ConfirmPayment';
const app_env = process.env.REACT_APP_ENV;
const Payment = (props) => {
    console.log(props)
    const payment_callback_url = "https://bookings.saniservice.com/";
    const PAYMENT_URL = '/service-booking/online/initiate-payment';
    const [choosePayment, setChoosePayment] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState();
    const [selectedTimeSlot, setSelectedTimeSlot] = useState();
    const [serviceAmount, setServiceAmount] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [vatAmount, setVatAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    useEffect(() => {
        const amount = props.items?.[props.selectedDate]?.latest_amount;
        if (amount) {
          let serviceAmount = amount;
          let subTotal = amount;
          if (props.selectedBooking) {
            subTotal = (serviceAmount - props.selectedBooking.service_amount);
          }
          setSubTotal(subTotal.toFixed(2));
          let vatAmount = subTotal * 0.05;
          console.log(vatAmount)
          let totalAmount = subTotal + vatAmount;
          setServiceAmount(serviceAmount.toFixed(2));
          setVatAmount(vatAmount.toFixed(2));
          setTotalAmount(totalAmount.toFixed(2));
          console.log(totalAmount)
        }else{
            console.log('amount is missing')
        }
      }, [props.items, props.selectedDate, props.selectedBooking]);

   const handleClickPayment = (e) => {
        setPaymentMethod(e.target.value);
        setChoosePayment(e.target.checked);
    };

    const handlesetSelectedTimeSlot = (e, timeSlot) => {
        setSelectedTimeSlot(timeSlot);
    }

    const handleNextStepClick = async (e) => {
        e.preventDefault();
        setShowConfirmPopup(true);
      };

       const handleCancelClick = () => {
    setShowConfirmPopup(false);
  };
       

        // props.handlePaymentClick(selectedTimeSlot, );
        const handleConfirmClick = async () => {
        setLoading(true);
        setError(false);
        await erpAxios.post(PAYMENT_URL, {
                account_id: props.items[props.selectedDate]['account_id'],
                property_id: props.items[props.selectedDate]['property_id'],
                service_date: props.selectedDate,
                selected_slot: selectedTimeSlot,
                service_duration: props.items[props.selectedDate]['duration'],
                service_amount: serviceAmount,
                sub_total: subTotal,
                vat_amount: vatAmount,
                total_amount: totalAmount,
                payment_method: paymentMethod,
                no_of_units: props.items[props.selectedDate]['ac_units'],  
                return_url: payment_callback_url, 
                previous_booking_id: props.selectedBooking ? props.selectedBooking.booking_id : null,
                previous_service_amount: props.selectedBooking ? props.selectedBooking.service_amount : null,
                user_type:'customer',
                app_env: app_env,
                user_id:props.items[props.selectedDate]['account_id']

        })
            .then(response => {
                console.log(response);

                if (response.data.status === true) {
                    
                        window.location.replace(response.data.payment_url)
                      
                    setLoading(false);
                    setError(false);
                }else{
                 //Error message dispaly here   
                }
                
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setError(true);
            })


        /*
                setSelectedTimeSlot(); 
                setChoosePayment(false);
                setPaymentMethod();
                setTipAmount();
                setTotalAmount(props.items && props.selectedDate && props.items[props.selectedDate]["latest_amount"]);
                */
    }

    const handlePrevStepClick = (e) => {
        e.preventDefault();
        props.doPreviousStep();
        setSelectedTimeSlot();
        setChoosePayment(false);
        setPaymentMethod();
       // setTotalAmount(serviceAmount);


    }


    return (
        <div>
  {(loading) && <div className="w-full flex justify-center mb-3"><Loader fullHeight={false} /></div>}
           
          
           

           
            {!showConfirmPopup && (
                
            <div>
                 <div className="w-full flex justify-end mb-3">
                <button onClick={handlePrevStepClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer"
                        width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15 5C9.47 5 5 9.47 5 15C5 20.53 9.47 25 15 25C20.53 25 25 20.53 25 15C25 9.47 20.53 5 15 5ZM19.3 19.3C18.91 19.69 18.28 19.69 17.89 19.3L15 16.41L12.11 19.3C11.72 19.69 11.09 19.69 10.7 19.3C10.5127 19.1132 10.4075 18.8595 10.4075 18.595C10.4075 18.3305 10.5127 18.0768 10.7 17.89L13.59 15L10.7 12.11C10.5127 11.9232 10.4075 11.6695 10.4075 11.405C10.4075 11.1405 10.5127 10.8868 10.7 10.7C11.09 10.31 11.72 10.31 12.11 10.7L15 13.59L17.89 10.7C18.28 10.31 18.91 10.31 19.3 10.7C19.69 11.09 19.69 11.72 19.3 12.11L16.41 15L19.3 17.89C19.68 18.27 19.68 18.91 19.3 19.3Z" fill="#333333" />
                    </svg>
                </button>
            </div>
                <p className="md:text-2xl text-xl font-light">Dear {props.account?.client_name},</p>
                
                
                
                <div className="mt-5 mb-7 gap-1.5 flex flex-col">
                    <p className="md:text-sm text-xs font-normal">Select the Slot:</p>
                    {props.items?.[props.selectedDate]?.time_ranges?.map((timeRange, index) => (
                        <div key={index} className="bg-primary-05 rounded hover:bg-primary/10">
                            <label className="flex items-center justify-between space-x-2 cursor-pointer py-1.5 px-2.5">
                                <p className="text-xs">{timeRange}</p>
                                <input
                                    type="checkbox"
                                    name="timeSlot"
                                    value={timeRange}
                                    className="h-5 w-5 appearance-none
   focus:outline-none  ring-0 rounded
   checked:bg-danger  focus:ring-0 focus:ring-primary/20 checked:text-danger border-1 border-primary/20
  checked:border-0 ring-offset-0 focus:ring-offset-0"
                                    onChange={(e) => handlesetSelectedTimeSlot(e, timeRange)}
                                    checked={selectedTimeSlot === timeRange}

                                />

                            </label>

                        </div>
                    ))}
                </div>

                <div className="w-full border-t py-2  border-b border-primary/30">
                    <div className="table align-middle w-full">
                        <div className="table-row md:text-sm text-xs font-normal text-primary">
                            <div className="table-cell w-8/12 pb-1">
                                SERVICE DATE
                            </div>
                            <div className="table-cell w-4/12 text-right pb-1">
                                AMOUNT
                            </div>
                        </div>
                        <div className="table-row text-xs font-normal text-primary">
                            <div className="table-cell w-8/12">
                            {props.selectedBooking && (
                                <div>
                                   
                                    <div className='font-medium pb-1'>
                                            {dayjs(props.selectedDate).format('MMMM D, YYYY')}
                                    </div>
                                    <div className='line-through text-primary/50 decoration-primary/50'>
                                            {dayjs(props.selectedBooking.service_date).format('MMMM D, YYYY')}
                                    </div>
                                </div>
                            )}
                             {!props.selectedBooking && (
                                <div>
                                    {dayjs(props.selectedDate).format('MMMM D, YYYY')}
                                </div>
                            )}
                            </div>
                            <div className="table-cell w-4/12 text-right">
                                
                                {props.selectedBooking && (
                                <div>
                                  
                                    <div className='font-medium pb-1'>
                                    {serviceAmount}
                                    </div>
                                    <div className='line-through text-primary/50 decoration-primary/50'>
                                    {props.selectedBooking.service_amount}
                                    </div>
                                </div>
                            )}
                            {!props.selectedBooking && (
                                <div>{serviceAmount}</div>
                            )}  
                            </div>
                        </div>

                    </div>

                </div>
               <div className="w-full py-2  border-b border-primary/30">
               <div className="table align-middle w-full">
                <div className="table-row text-xs font-normal  text-primary">
                    <div className="table-cell w-9/12 pb-1  pr-1 text-right">SUBTOTAL</div>
                    <div className="table-cell w-3/12  text-right">{subTotal}</div>
                    </div>
                    <div className="table-row text-xs font-normal text-primary">
                        <div className="table-cell w-9/12  pr-1 pb-1 text-right">VAT(5%)</div>
                        <div className="table-cell w-3/12 text-right">{vatAmount}</div>
                        </div>
                        </div>
                        </div>
                        <div className="w-full py-2 mb-3 border-b border-primary/30">
                        <div className="table align-middle w-full">
                            <div className="table-row md:text-sm text-xs font-normal  text-danger">
                                <div className="table-cell w-9/12  pr-1 text-right">TOTAL AMOUNT</div>
                                <div className="table-cell w-3/12  text-right">{totalAmount}</div>
                                </div>
                                </div>
                                </div>

             

               
                {totalAmount > 0 && (
                <div className="flex items-center mb-3 py-5 gap-10">




                    <label className="flex items-center gap-2.5">
                        <input type="radio" value="credit-card"
                            className="h-3 w-3 appearance-none
  focus:bg-danger focus:outline-none checked:bg-none ring ring-primary/10
   checked:bg-danger  focus:ring focus:ring-primary/20 text-danger border-0
  checked:border-5 ring-offset-0 focus:ring-offset-0"


                            id="pay-1" name="pay-1" onChange={handleClickPayment} checked={paymentMethod === "credit-card"}></input>
                        <div className="flex items-center gap-2.5"> <img src="./img/visa.png" />
                            <img src="./img/master.png" />
                        </div>

                    </label>
                    <label className="flex items-center gap-2.5">
                        <input type="radio" value="apple-pay"
                            className="h-3 w-3 appearance-none
  focus:bg-danger focus:outline-none checked:bg-none ring ring-primary/20
   checked:bg-danger  focus:ring focus:ring-primary/20 text-danger border-0
  checked:border-3 ring-offset-0 focus:ring-offset-0 "


                            id="pay-2" name="pay-2" onChange={handleClickPayment} checked={paymentMethod === "apple-pay"}></input>
                        <img src="./img/apple-pay.png" />

                    </label>

                </div>
                )}

                {totalAmount <= 0 && (
                <button className={`w-full text-white py-2 text-sm rounded font-bold ${!selectedTimeSlot ? "bg-danger/50 cursor-not-allowed" : "bg-danger hover:bg-danger/80"}`}
                    onClick={handleNextStepClick}
                    disabled={!selectedTimeSlot}
                >Confirm</button>
                )}

                {totalAmount > 0 && (
                <button className={`w-full text-white py-2 text-sm rounded font-bold ${!choosePayment || !selectedTimeSlot ? "bg-danger/50 cursor-not-allowed" : "bg-danger hover:bg-danger/80"}`}
                    onClick={handleNextStepClick}
                    disabled={!choosePayment || !selectedTimeSlot}
                >Pay Now</button>
                )}
            </div>

            )}
 {showConfirmPopup && (
                <ConfirmPayment
                    selectedTimeSlot={selectedTimeSlot}
                    selectedDate={props.selectedDate}
                    items={props.items}
                    propertyAddress={props.items[props.selectedDate]['property_address']}
                    primaryPropertyAddress={props.primaryPropertyAddress}
                    service_amount= {serviceAmount}
                    vat_amount= {vatAmount}
                    total_amount= {totalAmount}
                    payment_method= {paymentMethod}
                    onCancel={handleCancelClick}
                    onConfirm={handleConfirmClick}
                    account={props.account}
                    />
                )}


        </div>
    );
}
export default Payment;