import React, { useState, useEffect } from 'react'
import {useSearchParams} from 'react-router-dom';
import Steps from '../../Components/Customer/Steps'
import Calendar from '../../Components/Calendar'
import Payment from '../../Components/Customer/Payment'
import Confirm from '../../Components/Customer/Confirm'
import axios from '../../api/Axios';
import erpAxios from '../../api/ErpAxios'; 
import dayjs from "dayjs";
import Loader from '../../Components/Loader'
import PropertyList from '../../Components/Customer/PropertyList'
import BookingHistory from '../../Components/Customer/BookingHistory';
import SubHeader from '../../Components/Customer/SubHeader';
import Header from '../../Components/Customer/Header';
import Login from '../../Components/Customer/Login';
import useToken from '../../Components/Customer/useToken';
import useAccount from '../../Components/Customer/useAccount';
function Home(props) {
  // const query = new URLSearchParams(window.location.search);
  const [searchParams, setSearchParams] = useSearchParams();
  const [paymentReference, setPaymentReference] = useState()
  const [activeStep, setActiveStep] = useState(1)
  const { account, setAccount } = useAccount();
  const { token, setToken } = useToken();
  const [showBookingHistory, setShowBookingHistory] = useState(false);
  const [showProperties, setShowProperties] = useState(false);
  const steps = [
    {
      label: 'Booking',
      step: 1,
    },
    {
      label: 'Options',
      step: 2,
    },
    {
      label: 'Payment',
      step: 3,
    },
    {
      label: 'Confirm',
      step: 4,
    },
  ]
  const handleCloseBookingHistory = () => {
    setShowBookingHistory(false);
  };
  const logout = () => {
    setToken('')
    setAccount('')
   sessionStorage.removeItem("token");
   sessionStorage.removeItem("account_details");
}

  if (paymentReference) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    document.body.style.overflow = 'hidden';
  } else if (activeStep === 1 && !props.showProperties) {
    document.body.style.overflow = 'unset';
  } 

  const nextStep = () => {
    if (selectedDate) {
      setActiveStep(activeStep + 1)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      document.body.style.overflow = 'hidden';
      console.log(selectedDate)
    }

  }

  const prevStep = () => {
    setActiveStep(activeStep - 1)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    document.body.style.overflow = 'unset';
  }

  const backToHomePage = () => {
    setActiveStep(1)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    document.body.style.overflow = 'unset';
    if (searchParams.has('reference_uuid')) {
      searchParams.delete('reference_uuid');
      setSearchParams(searchParams);
    }
  }

  const totalSteps = steps.length

  const BASE_URL = '/test/';
  const [properties, setProperties] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(dayjs().format('MM'));
  const [currentYear, setCurrentYear] = useState(dayjs().format('YYYY'));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);  
  const [fetchTime, setFetchTime] = useState(Date.now());
  const [selectedBooking, setSelectedBooking] = useState();
  const [bookedServices, setBookedServices] = useState(null);
 
  const handleUndoSelectedBooking = () => {
    setSelectedBooking(null);
  };

  const handleModifyBooking = (booking) => {
    setSelectedBooking(booking);
    console.log('Selected booking:', booking);
  };

  // const handleCancelBooking = (booking) => {
  //   setSelectedBooking(booking);
  // };


  useEffect(() => {
    if(account){
    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      const timeDiff = currentTime - fetchTime;
      if (timeDiff >= 5 * 60 * 1000) { // 5 minutes
        setFetchTime(currentTime);
        fetchData(currentMonth, currentYear, account.primary_property_id);
      }
    }, 5 * 60 * 1000); // 5 minutes

    return () => clearInterval(intervalId);
  }

  }, [currentMonth, currentYear, account]);

  const fetchData = async (month, year, propertyId) => {
    try {
      setProperties(null);
      setLoading(true);
      setError(error);
      await axios.get(BASE_URL + propertyId, {
        params: {
          month: month,
          year: year
        }
      })
        .then(response => {
          setProperties(eval(response.data));
          setLoading(false);
          setError(false);
          console.log('Fetched data:', response.data);
        })
        .catch((error) => {
          console.log(error);
          setProperties(null);
          setLoading(false);
          setError(true);
        })

        //Fetch Booked Services: BEGIN
        setLoading(true);
        setError(false);
        setBookedServices(null);
        // const property_id = account.primary_property_id;
        await erpAxios.get('/service-booking/online/payment-status', {
          params: {
            selected_property_id: propertyId
          }
        })
          .then(resp => {
            console.log(resp)
          setBookedServices(resp.data);
          setLoading(false);
          setError(false);
          console.log('Fetched data:', resp.data);
        })
        .catch((error) => {
          console.log(error);
          setBookedServices(null);
          setLoading(false);
          setError(true);
        })

        //Fetch Booked Services: END

    } catch (error) {
      console.log(error);
    }

  };
  useEffect(() => {
    if(account){
   console.log('Fetching data for property ID:', account.primary_property_id);
    fetchData(currentMonth, currentYear, account.primary_property_id);
    if (searchParams.has('reference_uuid')) {
        setPaymentReference(searchParams.get('reference_uuid'))
    }else{
      setPaymentReference()
    }
  }
  }, [account, searchParams]);

  const handlePropertyChange = (propertyId) => {
    console.log('Selected property ID:', propertyId);
    setShowProperties(false);
    const newPrimaryPropertyAddress = account.properties[propertyId];
  setAccount({ ...account, primary_property_id: propertyId, primary_property_address: newPrimaryPropertyAddress });

  console.log('Updated account:', account);
    fetchData(currentMonth, currentYear, propertyId)
  };

  const handleDayClick = (day) => {
    setSelectedDate(day)
  };


  const handleMonthNavClick =(month, year) => {
    setCurrentMonth(month)
    setCurrentYear(year)
    fetchData(month, year, account.primary_property_id)
  }

  const handlePropertyOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      setShowProperties(false);
    }
  };
  
  const handleOptionOverlayClick = (event) => {
    if (event.target === event.currentTarget)
    prevStep();
  };
  
  const handleConfirmOverlayClick = (event) => {
    if (event.target === event.currentTarget)
    backToHomePage();
  };

  const handleBookingHistoryOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      handleCloseBookingHistory();
    }
  };
  if(!token || !account) {
    return <Login setToken={setToken} setAccount={setAccount}  />
  }


  return (
    
    <div className="">

    <Header  clickLogout={logout} clickBookingHistory={() => setShowBookingHistory(true)}/>
    
    <SubHeader account = {account} 
      setShowProperties={setShowProperties} 
      key={account.primary_property_id} />

{(loading) && <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><Loader /></div>}
   

      {/* Step Begin */}
      <section id="header-section" className=" bg-slate sm:max-w-full">
        <div className="container mx-auto  px-4">

          <Steps selected={activeStep}
          selectedBooking={selectedBooking} 
          items={steps}
          undoSelectedBooking={handleUndoSelectedBooking}
           />
        </div>
      </section>
      {/* Step End */}

      {/* Calendar Begin */}
      <section id="calendar-section" className=" bg-slate sm:max-w-full">
        <div className="container mx-auto  px-4">

          <Calendar currentMonth={currentMonth}
            currentYear={currentYear}
            items={properties}
            onSearch={(month, year) => handleMonthNavClick(month, year)}
            onDaySelect={handleDayClick}
            selectedDate={selectedDate}
            bookedServices={bookedServices}
            selectedBooking={selectedBooking} 
          />
        </div>
      </section>
      {/* Calendar End */}

      {/* Booking History Begin */}
<section id="booking-history-section" className={`${showBookingHistory ? "" : "hidden"}`}>
      <div className="bg-white/50 w-screen h-screen inset-0 absolute top-0 left-0 backdrop-blur-sm"></div>
      <div className="flex absolute inset-0 h-screen w-screen items-center" onClick={handleBookingHistoryOverlayClick}>
      <div className="md:w-100 flex bg-white rounded gap-1 shadow-2xl shadow-primary/20 md:mx-auto md:py-5 md:px-8 mx-12 px-9 py-6">
          <BookingHistory 
            selectedPropertyId={account.primary_property_id}
            onClose={handleCloseBookingHistory}
            onModifyBooking={handleModifyBooking}
            showBookingHistory={showBookingHistory}
            account={account}
            bookedServices={bookedServices}
            // onCancelBooking={handleCancelBooking}
          />
        </div>
      </div>
    </section>
    {/* Booking History End */}

        {/* Properties Begin */}
<section id="property-section" className={`${showProperties ? '' : 'hidden'} sm:max-w-full`}>
<div className="bg-white/10 w-screen h-screen inset-0 absolute top-0 left-0 backdrop-blur-sm" onClick={handlePropertyOverlayClick}></div>
        <div className="flex absolute top-44 left-0 right-0 h-auto items-center justify-center">
        <div className="w-96 bg-white  rounded gap-1 shadow-2xl shadow-primary/20 mx-auto py-5 px-8">
      <PropertyList 
        visible={showProperties} 
        setVisible={setShowProperties} 
        properties={account.properties} 
        handlePropertyChange={handlePropertyChange}
        onClose={() => setShowProperties(false)}
        selectedPropertyId={account.primary_property_id}
        account={account}
      />
    </div>
  </div>
</section>
{/* Properties End */}

      {/* Options Begin */}
      <section id="option-section" className={`${activeStep === 2 ? "" : "hidden"}`}>
        <div className="bg-white/50 w-screen h-screen inset-0 absolute top-0 left-0 backdrop-blur-sm"></div>
        <div className="flex absolute inset-0 h-screen w-screen items-center" onClick={handleOptionOverlayClick}>
          <div className="md:w-96 w-54 bg-white  rounded gap-1 shadow-2xl shadow-primary/20 mx-auto py-5 px-8">
            <Payment
              //handlePaymentClick={handlePaymentClick}
              primaryPropertyAddress={account.primary_property_address}
              doPreviousStep={prevStep}
              items={properties}
              selectedDate={selectedDate}
              selectedBooking={selectedBooking}
              account={account}
            />

          </div>
        </div>
      </section>
      {/* Options End */}

      {/* Confirm Begin */}
      <section id="confirm-section" className={`${paymentReference  ? "" : "hidden"}`}>
        <div className="bg-white/50 w-screen h-screen inset-0 absolute top-0 left-0 backdrop-blur-sm"></div>
        <div className="flex absolute inset-0 h-screen w-screen items-center" onClick={handleConfirmOverlayClick}>
          <div className="md:w-96 w-54 bg-white  rounded gap-1 shadow-2xl shadow-primary/20 md:mx-auto mx-9 md:py-5 md:px-8 px-5 py-4">
            <Confirm
              //items={properties}
             // selectedDate={selectedDate}
             paymentReference={paymentReference}
             // doPreviousStep={prevStep}
              goBackToHomePage={backToHomePage} />
          </div>
        </div>
      </section>
      {/* Confirm End */}

      




      <section id="button-section" className=" bg-slate">
        <div className="container mx-auto md:px-4 px-8">
          <div className="pb-14">
            <p className="pb-7 md:text-sm text-xs text-primary/70 md:text-left text-center">All prices are shown in AED. Conditions may apply for each price. Availability and price are not guaranteed until you receive a booking confirmation email containing a booking reference.*</p>

            <div className={`flex justify-center md:block ${(activeStep > 1 && activeStep < totalSteps) ? 'flex justify-between' : (activeStep === totalSteps) ? 'md:text-left' : 'md:text-right'} `}>

              {/* <button
                onClick={prevStep}
                className={`text-white text-2xl font-bold py-3.5 px-32 rounded bg-danger hover:bg-danger/80
            ${activeStep === 1 ? 'hidden' : ''}
            `}>
                Previous
              </button> */}

              <button
  onClick={nextStep}
  className={`text-white md:text-2xl text-md font-bold py-2 md:py-3.5 px-32 rounded 
    ${activeStep === totalSteps ? 'hidden' : ''}
    ${selectedDate ? "bg-danger hover:bg-danger/80" : 'bg-danger/50 cursor-not-allowed'}
    flex justify-center md:inline-block
  `}
>
  Continue
</button>
            </div>
          </div>
        </div>
      </section>




    </div>

  )
}

export default Home