import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  // Navigate,
  // Link,
} from "react-router-dom";

import Footer from './Components/Footer';

import CustomerHome from './Pages/Customer/Home';
import SalesHome from './Pages/Sales/Home';


function App() {

  return (
    <div className="w-full h-screen flex flex-col">
      <BrowserRouter>
        <div className="flex-1">
          <Routes>
            <Route exact path="/" element={<CustomerHome />} />
            <Route path="/admin" element={<SalesHome />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
